import { TicketshopPage } from "components/TicketshopPage";
import React from "react";

export default function BratpopPage() {
  return (
    <TicketshopPage
      scriptString={`<script data-name-filter="bratpop" src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js?salesChannel=whitelabel-website" data-fixr-shop-id="be31447a-9d7b-4108-bde6-c9cb9a5287e1" data-fixr-theme="light"></script>`}
      imageSrc="/logo/bratpop-logo.png"
      backgroundSrc="/hero/bratpop-hero.jpg"
      imageMaxWidth="462px"
      title="BRATPOP!"
      description={`From Britpop to Brat Summer and everything in between, BRATPOP! oozes music with attitude. A chaotic, fun & feral dancefloor that pulls inspo from the iconic Y2K era whilst putting 2 fingers up to the music we’re ’supposed to like’. It’s unapologetically pop! It’s clean and pretty but messy and carefree, it’s Britney and Taylor but Liam and Noel. A celebration of carefree clubbing culture every single Tuesday at the newly refurbished HiFi Club powered by POP and FREE CRISPS!`}
    />
  );
}
